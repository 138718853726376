import { CustomersState, UploadCSVModal } from "./types";
import { ActionType, createReducer } from "typesafe-actions";
import * as actions from "./actions";
import { cleanCSVData } from "../../../functions/cleanCSVData";
import { CHUNK_SIZE } from "../../middleware/chunkedUpload";

export type CustomerAction = ActionType<typeof actions>;

const initialState: CustomersState = {
  ui: {
    addSingleCustomerModal: {
      isOpen: false,
      isPending: false,
      data: {
        email_address: "",
        phone_number: "",
        location_name: "",
      },
    },
    uploadCSVModal: {
      isOpen: false,
      forcedLocationName: "",
      page: 1,
      data: null,
      error: {},
      bulkUploadId: null,
      locationMap: {},
      locationToSurveyMap: {},
      headers: {
        email_address: "",
        phone_number: "",
        location_name: "",
      },
      chunkedRequest: {
        isPending: false,
        toUpload: [],
        inProgress: [],
        completed: [],
      },
    },
  },
};

const reducer = createReducer(initialState)
  .handleAction(actions.addSingleCustomer, (state) => ({
    ...state,
    ui: {
      ...state.ui,
      addSingleCustomerModal: {
        ...state.ui.addSingleCustomerModal,
        isOpen: true,
      },
    },
  }))
  .handleAction(actions.cancelAddSingleCustomer, (state) => ({
    ...state,
    ui: {
      ...state.ui,
      addSingleCustomerModal: {
        ...state.ui.addSingleCustomerModal,
        isOpen: false,
        data: {
          email_address: "",
          phone_number: "",
          location_name: "",
        },
      },
    },
  }))
  .handleAction(actions.updateSingleCustomerDraft, (state, { payload }) => ({
    ...state,
    ui: {
      ...state.ui,
      addSingleCustomerModal: {
        ...state.ui.addSingleCustomerModal,
        data: {
          ...state.ui.addSingleCustomerModal.data,
          ...Object.entries(payload).reduce(
            (prev, next) => ({
              ...prev,
              [next[0]]: (next[1] || "").trim(),
            }),
            {}
          ),
        },
      },
    },
  }))
  .handleAction(actions.addCustomerPending, (state) => ({
    ...state,
    ui: {
      ...state.ui,
      addSingleCustomerModal: {
        ...state.ui.addSingleCustomerModal,
        isPending: true,
      },
    },
  }))
  .handleAction(actions.addCustomerSuccess, (state) => ({
    ...state,
    ui: {
      ...state.ui,
      addSingleCustomerModal: {
        ...state.ui.addSingleCustomerModal,
        isPending: false,
        isOpen: false,
        data: {
          email_address: "",
          phone_number: "",
          location_name: "",
        },
      },
    },
  }))
  .handleAction(actions.addCustomerError, (state) => ({
    ...state,
    ui: {
      ...state.ui,
      addSingleCustomerModal: {
        ...state.ui.addSingleCustomerModal,
        isPending: false,
      },
    },
  }))
  .handleAction(actions.uploadCustomerCSV, (state) => ({
    ...state,
    ui: {
      ...state.ui,
      uploadCSVModal: {
        ...state.ui.uploadCSVModal,
        isOpen: true,
        error: {},
        page: 1,
        locationMap: {},
      },
    },
  }))
  .handleAction(
    actions.setUploadCustomerCSVModalPage,
    (state, { payload: page }) => ({
      ...state,
      ui: {
        ...state.ui,
        uploadCSVModal: {
          ...state.ui.uploadCSVModal,
          page,
          error: {},
        },
      },
    })
  )
  .handleAction(
    actions.mapLocationName,
    (state, { payload: { raw, standardised } }) => ({
      ...state,
      ui: {
        ...state.ui,
        uploadCSVModal: {
          ...state.ui.uploadCSVModal,
          locationMap: {
            ...state.ui.uploadCSVModal.locationMap,
            [raw]: standardised,
          },
        },
      },
    })
  )
  .handleAction(actions.cancelUploadCustomerCSV, (state) => ({
    ...state,
    ui: {
      ...state.ui,
      uploadCSVModal: {
        ...state.ui.uploadCSVModal,
        isPending: false,
        isOpen: false,
        data: null,
        headers: {
          email_address: "",
          phone_number: "",
          location_name: "",
        },
      },
    },
  }))
  .handleAction(actions.loadCustomerCSVData, (state, { payload }) => ({
    ...state,
    ui: {
      ...state.ui,
      uploadCSVModal: {
        ...state.ui.uploadCSVModal,
        data: payload
          .filter((row) => !!row.length) // remove empty rows
          .filter((row) => row.find((col) => !!col)) // remove rows containing only empty strings
          .map((row: string[]) => row.map((cell) => String(cell).trim())),
        error: {},
        headers: {
          email_address: "",
          phone_number: "",
          location_name: "",
        },
      },
    },
  }))
  .handleAction(actions.selectCustomerCSVHeader, (state, { payload }) => {
    const existing = Object.entries(state.ui.uploadCSVModal.headers).find(
      ([key, value]) => Object.values(payload).indexOf(value) > -1
    );
    return {
      ...state,
      ui: {
        ...state.ui,
        uploadCSVModal: {
          ...state.ui.uploadCSVModal,
          headers: {
            ...state.ui.uploadCSVModal.headers,
            ...payload,
            ...(existing ? { [existing[0]]: "" } : {}),
          },
        },
      },
    };
  })
  .handleAction(actions.setForcedLocationName, (state, { payload }) => ({
    ...state,
    ui: {
      ...state.ui,
      uploadCSVModal: {
        ...state.ui.uploadCSVModal,
        forcedLocationName: payload,
      },
    },
  }))
  .handleAction(actions.setLocationToSurveyMap, (state, { payload }) => ({
    ...state,
    ui: {
      ...state.ui,
      uploadCSVModal: {
        ...state.ui.uploadCSVModal,
        locationToSurveyMap: payload,
      },
    },
  }))
  .handleAction(actions.saveNextCSVChunk, (state) => {
    let chunkedRequest: UploadCSVModal["chunkedRequest"];
    if (!state.ui.uploadCSVModal.chunkedRequest.isPending) {
      // this is the first call, clean the data and split the first batch
      const {
        data,
        forcedLocationName,
        headers: { email_address, phone_number, location_name },
      } = state.ui.uploadCSVModal;
      const cleaned = cleanCSVData(
        data!,
        email_address,
        phone_number,
        location_name
      );

      // at this point, also standardise the locations
      const { locationMap } = state.ui.uploadCSVModal;

      const standardised = cleaned.map(({ location_name, ...rest }) => ({
        ...rest,
        location_name: (location_name && locationMap[location_name]) || null,
      }));

      // If there is only one live location then we use `forcedLocationName`
      // to ensure that the location is still sent
      if (forcedLocationName) {
        for (const location of standardised) {
          location.location_name = forcedLocationName;
        }
      }

      chunkedRequest = {
        isPending: true,
        toUpload:
          standardised.length >= CHUNK_SIZE
            ? standardised.slice(CHUNK_SIZE)
            : [],
        inProgress:
          standardised.length >= CHUNK_SIZE
            ? standardised.slice(0, CHUNK_SIZE)
            : standardised,
        completed: [],
      };
    } else {
      // this is a recursive call, split the next batch
      const { toUpload, completed } = state.ui.uploadCSVModal.chunkedRequest;
      chunkedRequest = {
        isPending: true,
        toUpload:
          toUpload.length > CHUNK_SIZE ? toUpload.slice(CHUNK_SIZE) : [],
        inProgress:
          toUpload.length >= CHUNK_SIZE
            ? toUpload.slice(0, CHUNK_SIZE)
            : toUpload,
        completed: [...completed],
      };
    }
    return {
      ...state,
      ui: {
        ...state.ui,
        uploadCSVModal: {
          ...state.ui.uploadCSVModal,
          chunkedRequest,
        },
      },
    };
  })
  .handleAction(actions.saveCustomerCSVDataSuccess, (state, action) => ({
    ...state,
    ui: {
      ...state.ui,
      uploadCSVModal: {
        ...state.ui.uploadCSVModal,
        bulkUploadId: action.payload.bulk_upload_id,
        error: {},
        chunkedRequest: {
          ...state.ui.uploadCSVModal.chunkedRequest,
          inProgress: [],
          completed: [
            ...state.ui.uploadCSVModal.chunkedRequest!.completed,
            ...state.ui.uploadCSVModal.chunkedRequest!.inProgress,
          ],
        },
      },
    },
  }))
  .handleAction(actions.chunkedUploadComplete, (state) => ({
    ...state,
    ui: {
      ...state.ui,
      uploadCSVModal: {
        ...state.ui.uploadCSVModal,
        chunkedRequest: {
          isPending: false,
          toUpload: [],
          inProgress: [],
          completed: [],
        },
        isOpen: false,
        bulkUploadId: null,
        data: null,
        headers: {
          email_address: "",
          phone_number: "",
          location_name: "",
        },
      },
    },
  }))
  .handleAction(
    actions.saveCustomerCSVDataError,
    (state, { payload: error }) => ({
      ...state,
      ui: {
        ...state.ui,
        uploadCSVModal: {
          ...state.ui.uploadCSVModal,
          isPending: false,
          error: error || {},
          chunkedRequest: {
            ...state.ui.uploadCSVModal.chunkedRequest,
            isPending: false,
            inProgress: [],
          },
        },
      },
    })
  )
  .handleAction(
    actions.updateCustomerCSVLocation,
    (state, { payload: location_name }) => ({
      ...state,
      ui: {
        ...state.ui,
        uploadCSVModal: {
          ...state.ui.uploadCSVModal,
          location_name,
        },
      },
    })
  );

export { reducer as customerReducer };
